import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { usePostForgotPasswordMutation } from "../../services/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Loader, showError, showToast } from "../../constants";

const Login = () => {
  const navigate = useNavigate();

  const [ForgotPasswordMutation, { isLoading }] =
    usePostForgotPasswordMutation();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let body = {
        key: formik.values.email,
      };

      try {
        const response = await ForgotPasswordMutation(body).unwrap();
        if (response?.statusCode === 200) {
          const data = {
            email: formik.values.email,
            page: "forgot",
          };
          navigate("/verify-otp", { state: data });
          showToast(response?.message);
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    },
  });

  return (
    <div className="authh">
      <Loader isLoad={isLoading} />
      <div className="conta_iner">
        <div className="auth_parnt">
          <div className="auth_left">
            <figure
              style={{ height: "70px", width: "140px", margin: "0 auto" }}
            >
              <img
                src="/static/images/logo.png"
                alt=""
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </figure>
            <h2>Forgot Password</h2>
            <Box sx={{ paddingBottom: 5 }}></Box>
            <form>
              <FormControl sx={{ width: "100%" }}>
                <Typography className="custom_label">Email Address</Typography>
                <TextField
                  className="text_field"
                  hiddenLabel
                  placeholder="Email Address"
                  fullWidth
                  name="email"
                  type="email"
                  variant="outlined"
                  // id="email"
                  // onChange={(val) => {
                  //   if (val.target.value === " " || val.target.value === ".") {
                  //   } else {
                  //     formik.handleChange(val);
                  //   }
                  // }}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.email}
                  // helperText={formik.touched.email && formik.errors.email}
                />
              </FormControl>
              <Box sx={{ pt: 3 }}>
                <Button
                  className="btn btn_primary"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => navigate("/verify-otp", { state: "forgot" })}
                >
                  Send
                </Button>
              </Box>
            </form>
          </div>
          <div className="auth_ryt">
            <div className="absolute_div">
              <h3>Welcome Back</h3>
              <p>Already signed up, enter your details and start exploring</p>
              <Box sx={{ pt: 3, display: "flex", justifyContent: "center" }}>
                <Button
                  className="btn"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => navigate("/")}
                >
                  Sign In
                </Button>
              </Box>
            </div>
            <figure>
              <img src="/static/images/cook1.jpg" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
