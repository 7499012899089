import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Input } from "../../components";

const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [emaila, setEmaila] = useState("");

  const handleEmailChange = (value: any) => {
    setEmaila(value);
  };

  return (
    <div className="authh">
      <div className="conta_iner">
        <div className="auth_parnt">
          <div className="auth_left">
            <figure
              style={{ height: "70px", width: "140px", margin: "0 auto" }}
            >
              <img
                src="/static/images/logo.png"
                alt=""
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </figure>
            <h2>Sign In to Bucketlist </h2>
            <div className="google_div">
              {email ? (
                <img
                  onClick={() => setEmail(false)}
                  className="google_icon"
                  src="/static/images/call.png"
                  alt=""
                />
              ) : (
                <img
                  onClick={() => setEmail(true)}
                  className="email_fig"
                  src="/static/images/email.png"
                  alt=""
                />
              )}

              <img
                className="google_icon"
                src="/static/images/google.png"
                alt=""
              />
              <img
                className="google_icon"
                src="/static/images/facebook.png"
                alt=""
              />
            </div>
            <h5>Or use your {email ? "email" : "phone number"}:</h5>
            <div className="auth_form">
              {email ? (
                <>
                  <FormControl sx={{ mb: 2, width: "100%" }}>
                    <Typography className="custom_label">
                      Email Address
                    </Typography>
                    {/* <Input
                      placeholder="Email Address"
                      value={emaila}
                      onChange={handleEmailChange}
                      // onChange={(val) => {
                      //   if (val === " " || val === ".") {
                      //   } else if (isString(val)) {
                      //     setEmaila(val);
                      //   }
                      // }}
                    /> */}
                    <TextField
                      hiddenLabel
                      placeholder="Email Address"
                      fullWidth
                      name="email"
                      type="email"
                      variant="outlined"
                    />
                  </FormControl>
                  <FormControl sx={{ mb: 2, width: "100%" }}>
                    <Typography className="custom_label">Password</Typography>
                    <TextField
                      hiddenLabel
                      placeholder="Password"
                      fullWidth
                      name="password"
                      type={showPassword ? "text" : "password"}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment className="eye_btn" position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box className="remember_box">
                      <FormControlLabel
                        control={<Checkbox />}
                        // checked={formik.values.remember}
                        name="remember"
                        // onChange={formik.handleChange}
                        label="Remember me"
                      />
                    </Box>
                    <Box
                      className="anchor_link"
                      onClick={() => navigate("/forgot-password")}
                    >
                      <Typography>Forgot Password?</Typography>
                    </Box>
                  </Box>
                </>
              ) : (
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Phone Number</Typography>
                  <PhoneInput
                    country={"us"}
                    placeholder="0 (000) 000-000"
                    enableSearch={true}
                    inputStyle={{ width: "100%" }}
                  />
                </Grid>
              )}

              <Box sx={{ pt: 3 }}>
                <Button
                  className="btn btn_primary"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() =>
                    email
                      ? navigate("/dashboard")
                      : navigate("/verify-otp", { state: "login" })
                  }
                >
                  Sign In
                </Button>
              </Box>
              <h4>
                Let's Join Us.
                <span onClick={() => navigate("/sign-up")}> Sign Up</span>
              </h4>
            </div>
          </div>
          <div className="auth_ryt">
            <div className="absolute_div">
              <h3>Hello There, Join Us</h3>
              <p>Enter your personal details and join the ambitio community</p>
              <Box sx={{ pt: 3, display: "flex", justifyContent: "center" }}>
                <Button
                  className="btn"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => navigate("/sign-up")}
                >
                  Sign Up
                </Button>
              </Box>
            </div>
            <figure>
              <img src="/static/images/cook1.jpg" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
