import { ChangeEvent, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const AddAdvertisement = () => {
  const navigate = useNavigate();

  const [image, setImage] = useState<string | undefined>("");
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Add Advertisement</h1>

          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-advertisements");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Advertisement Image
                  </Typography>
                  {image ? (
                    <div className="upload_image_preview2">
                      <CardMedia component="img" image={image} alt="photo" />
                      <CancelIcon
                        onClick={() => {
                          setImage("");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image_bnr">
                      <label htmlFor="icon-button-file">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const files = (event.target as HTMLInputElement)
                              .files;
                            if (files && files[0].type.includes("image")) {
                              setImage(URL.createObjectURL(files[0]));
                            }
                          }}
                          //   onChange={handleImageUpload}
                        />
                        <Button component="span" className="upload_image_btn">
                          {/* <img
                          src={
                            image
                              ? image
                              : "/static/images/user_placeholder.png"
                          }
                          alt=""
                        /> */}
                          <AddIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} />

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Advertisement Title
                  </Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    placeholder="Advertisement Title"
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Web Link</Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    placeholder="Web Link"
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">No. of hours</Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    placeholder="No. of hours"
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Hourly charge{" "}
                  </Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    placeholder="Hourly charge "
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Total Cost</Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    placeholder="Total Cost"
                  />
                </Grid>
                {/* <Grid item xs={4}>
                <Typography className="custom_label">
                  Start Date
                </Typography>
                <TextField
                  className="text_field"
                  hiddenLabel
                  type={"date"}
                  name="startDate"
                  variant="outlined"
                  fullWidth
                  id="startDate"
                  // value={formik.values.startDate}
                  // onChange={formik.handleChange}
                  // helperText={
                  //   formik.touched.startDate && formik.errors.startDate
                  // }
                />
              </Grid> */}

                {/* <Grid item xs={4}>
                <Typography className="custom_label">End Date</Typography>
                <TextField
                  className="text_field"
                  hiddenLabel
                  type={"date"}
                  name="endDate"
                  variant="outlined"
                  fullWidth
                  id="endDate"
                  // value={formik.values.endDate}
                  // onChange={formik.handleChange}
                  // helperText={
                  //   formik.touched.endDate && formik.errors.endDate
                  // }
                />
              </Grid> */}
                <br />
              </Grid>

              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                  // onClick={() => setError(true)}
                >
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddAdvertisement;
