import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { usePostSignUpMutation } from "../../services/auth";
import { Loader, showError } from "../../constants";

const SignUp = () => {
  const navigate = useNavigate();
  const [signUpMutation, { isLoading }] = usePostSignUpMutation();

  const [email, setEmail] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
      password: Yup.string()
        .label("Password")
        .required("Password is required!")
        .min(6, "Password must be at least 6 characters.")
        .matches(
          /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
          "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character. "
        ),

      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match.")
        .required("Confirm password is required."),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        key: formik.values.email,
        password: formik.values.password,
      };

      try {
        const response = await signUpMutation(body).unwrap();
        if (response?.statusCode === 200) {
          navigate("/verify-otp", { state: formik.values.email });
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    },
  });

  return (
    <div className="authh">
      <Loader isLoad={isLoading} />
      <div className="conta_iner">
        <div className="auth_parnt">
          <div className="auth_left">
            <figure
              style={{ height: "70px", width: "140px", margin: "0 auto" }}
            >
              <img
                src="/static/images/logo.png"
                alt=""
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </figure>
            <h2>Create an Account</h2>
            <div className="google_div">
              {email ? (
                <img
                  onClick={() => setEmail(false)}
                  className="google_icon"
                  src="/static/images/call.png"
                  alt=""
                />
              ) : (
                <img
                  onClick={() => setEmail(true)}
                  className="email_fig"
                  src="/static/images/email.png"
                  alt=""
                />
              )}

              <img
                className="google_icon"
                src="/static/images/google.png"
                alt=""
              />
              <img
                className="google_icon"
                src="/static/images/facebook.png"
                alt=""
              />
            </div>
            <h5>
              Or use your {email ? "email" : "phone number"} for registration:{" "}
            </h5>
            <form>
              <div className="auth_form">
                {email ? (
                  <>
                    <FormControl sx={{ mb: 2, width: "100%" }}>
                      <Typography className="custom_label">
                        Email Address
                      </Typography>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        placeholder="Email Address"
                        fullWidth
                        // name="email"
                        // type="email"
                        // variant="outlined"
                        // id="email"
                        // onChange={(val) => {
                        //   if (
                        //     val.target.value === " " ||
                        //     val.target.value === "."
                        //   ) {
                        //   } else {
                        //     formik.handleChange(val);
                        //   }
                        // }}
                        // onBlur={formik.handleBlur}
                        // value={formik.values.email}
                        // helperText={formik.touched.email && formik.errors.email}
                      />
                    </FormControl>
                    <FormControl sx={{ mb: 2, width: "100%" }}>
                      <Typography className="custom_label">Password</Typography>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        placeholder="Password"
                        fullWidth
                        name="password"
                        id="password"
                        // onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        // value={formik.values.password}
                        // helperText={
                        //   formik.touched.password && formik.errors.password
                        // }
                        type={showPassword ? "text" : "password"}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment className="eye_btn" position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                onMouseDown={() =>
                                  setShowPassword(!showPassword)
                                }
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <FormControl sx={{ mb: 2, width: "100%" }}>
                      <Typography className="custom_label">
                        Confirm Password
                      </Typography>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        placeholder="Password"
                        fullWidth
                        // name="passwordConfirmation"
                        // id="passwordConfirmation"
                        // onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        // value={formik.values.passwordConfirmation}
                        // helperText={
                        //   formik.touched.passwordConfirmation &&
                        //   formik.errors.passwordConfirmation
                        // }
                        type={confirmShowPassword ? "text" : "password"}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment className="eye_btn" position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setConfirmShowPassword(!confirmShowPassword)
                                }
                                onMouseDown={() => !confirmShowPassword}
                                edge="end"
                              >
                                {confirmShowPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </>
                ) : (
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      country={"us"}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      inputStyle={{ width: "100%" }}
                    />
                  </Grid>
                )}

                <Box sx={{ pt: 3 }}>
                  <Button
                    className="btn btn_primary"
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={() =>
                      navigate("/verify-otp", { state: formik.values.email })
                    }
                  >
                    Sign Up
                  </Button>
                </Box>
              </div>
            </form>
          </div>
          <div className="auth_ryt">
            <div className="absolute_div">
              <h3>Welcome Back</h3>
              <p>Already signed up, enter your details and start exploring</p>
              <Box sx={{ pt: 3, display: "flex", justifyContent: "center" }}>
                <Button
                  className="btn"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => navigate("/")}
                >
                  Sign In
                </Button>
              </Box>
            </div>
            <figure>
              <img src="/static/images/cook1.jpg" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
