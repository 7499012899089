import { useNavigate } from "react-router-dom";
import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";

const AdvertisementDetails = () => {
  const navigate = useNavigate();
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Advertisement Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-advertisements");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box view_box_list">
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <figure className="banner_img">
                  <img src="/static/images/cook1.jpg" alt="" />
                </figure>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} />
              {/* <Grid item xs={10} className="view_box_list"> */}
              {/* <Grid container spacing={3}> */}
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Advertisement Title</Typography>
                  <Typography component="p">See the Northern Lights</Typography>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Web Link</Typography>
                  <Typography component="p">www.northernlights.com</Typography>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">
                    Targeted activity category
                  </Typography>
                  <Typography component="p">Category</Typography>
                </Box>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Advertisement Status</Typography>
                  <Typography component="p">Approved</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Number of hours</Typography>
                      <Typography component="p">10</Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        Hourly charge for ad
                      </Typography>
                      <Typography component="p">$2</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Total Cost</Typography>
                      <Typography component="p">$20</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AdvertisementDetails;
