import { ChangeEvent, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const GenerateTicket = () => {
  const navigate = useNavigate();
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Generate Ticket</h1>

          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/help-and-support");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Name</Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    placeholder="Name"
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Email</Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    placeholder="Email"
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Issue Type</Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    placeholder="Issue Type"
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Message</Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="firstName"
                    variant="outlined"
                    multiline
                    minRows={4}
                    maxRows={4}
                    fullWidth
                    placeholder="Message"
                  />
                </Grid>

                {/* <Grid item xs={4}>
              <Typography className="custom_label">
                Start Date
              </Typography>
              <TextField
                className="text_field"
                hiddenLabel
                type={"date"}
                name="startDate"
                variant="outlined"
                fullWidth
                id="startDate"
                // value={formik.values.startDate}
                // onChange={formik.handleChange}
                // helperText={
                //   formik.touched.startDate && formik.errors.startDate
                // }
              />
            </Grid> */}

                {/* <Grid item xs={4}>
              <Typography className="custom_label">End Date</Typography>
              <TextField
                className="text_field"
                hiddenLabel
                type={"date"}
                name="endDate"
                variant="outlined"
                fullWidth
                id="endDate"
                // value={formik.values.endDate}
                // onChange={formik.handleChange}
                // helperText={
                //   formik.touched.endDate && formik.errors.endDate
                // }
              />
            </Grid> */}
                <br />
              </Grid>

              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                  // onClick={() => setError(true)}
                >
                  Submit
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};
export default GenerateTicket;
