import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Box, Button, FormControl, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader, showError, showToast } from "../../../constants";
import {
  usePostForgotPasswordMutation,
  usePostVerifyOtpMutation,
} from "../../../services/auth";
import { useAppDispatch } from "../../../hooks/store";
import { temporaryToken } from "../../../reducers/authSlice";

const otpStyle = {
  width: "50px",
  height: "50px",
  "box-sizing": "border-box",
  margin: "0 auto",
};

const VerifyOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { state } = location;
  console.log("state: ", state);
  const [optVerificationMutation] = usePostVerifyOtpMutation();
  const [ForgotPasswordMutation, { isLoading }] =
    usePostForgotPasswordMutation();

  const [otp, setOtp] = useState<string>("");
  const [countDown, setCountDown] = useState<number>(59);
  const [error, setError] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (otp?.length === 4) {
      setError(false);

      const body = {
        key: state?.email,
        code: otp,
      };

      try {
        const response = await optVerificationMutation(body).unwrap();
        if (response?.statusCode === 200) {
          dispatch(temporaryToken({ tempToken: response?.data?.token }));
          navigate(
            state?.page === "forgot"
              ? "/reset-password"
              : state?.page === "login"
              ? "/dashboard"
              : "/profile-setup",
            { replace: true }
          );
        }
      } catch (error: any) {
        if (error?.data?.message) {
          showError(error?.data?.message || "");
        }
      }
    } else {
      setError(true);
    }
  };

  const handleResendOtp = async () => {
    let body = {
      key: state?.email,
    };
    try {
      const response = await ForgotPasswordMutation(body).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message);
        setCountDown(59);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <div className="authh">
      <Loader isLoad={isLoading} />
      <div className="conta_iner">
        <div className="auth_parnt">
          <div className="auth_left">
            <figure
              style={{ height: "70px", width: "140px", margin: "0 auto" }}
            >
              <img
                src="/static/images/logo.png"
                alt=""
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </figure>
            <h2>Verify OTP</h2>
            <Box sx={{ paddingBottom: 5 }}></Box>
            <FormControl className="opt_fields" sx={{ width: "100%" }}>
              <OtpInput
                containerStyle={{ width: "70%", margin: "auto" }}
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
                inputStyle={otpStyle}
                inputType="tel"
              />
              <br />
              {error && otp.length !== 4 ? (
                <h6 className="err_msg" style={{ textAlign: "center" }}>
                  This field is required
                </h6>
              ) : (
                ""
              )}
            </FormControl>

            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                // onClick={handleSubmit}
                onClick={() =>
                  state === "forgot"
                    ? navigate("/reset-password")
                    : state === "login"
                    ? navigate("/dashboard")
                    : navigate("/profile-setup")
                }
              >
                Verify Otp
              </Button>
            </Box>
            {/* {countDown === 0 ? ( */}
            <Box
              sx={{
                textAlign: "center",
                pt: 2,
              }}
            >
              <Typography
                className="anchor_link"
                sx={{ cursor: "pointer" }}
                // onClick={handleResendOtp}
              >
                Resend Otp
              </Typography>
            </Box>
            {/* ) : (
              <Box>
                <Typography
                  // className="anchor_link"
                  sx={{
                    color: "grey",
                    pt: 2,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  The verify code will expire in{" "}
                  <p style={{ margin: 0, color: "#d63853", paddingLeft: 3 }}>
                    00 : {countDown}
                  </p>
                </Typography>
              </Box>
            )} */}
          </div>
          <div className="auth_ryt">
            <div className="absolute_div">
              <h3>Welcome Back</h3>
              <p>Already signed up, enter your details and start exploring</p>
              <Box sx={{ pt: 3, display: "flex", justifyContent: "center" }}>
                <Button
                  className="btn"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => navigate("/")}
                >
                  Sign In
                </Button>
              </Box>
            </div>
            <figure>
              <img src="/static/images/cook1.jpg" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
