import { PropsWithoutRef } from "react";
import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SwitchVideoIcon from "@mui/icons-material/SwitchVideo";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import { SIDEBAR_WIDTH } from "../constants";
import QuizIcon from "@mui/icons-material/Quiz";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import RateReviewIcon from "@mui/icons-material/RateReview";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const location = useLocation();
  const navigate = useNavigate();

  const drawer = (
    <>
      <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
        <figure>
          <img
            src="/static/images/logo.png"
            alt=""
            onClick={() => navigate("/dashboard")}
          />
        </figure>
        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "white",
            display: { lg: "none" },
          }}
          onClick={handleSidebarToggle}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>

      <List sx={{ flexGrow: 1 }} className="sidebr-lst">
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/dashboard"}
          onClick={() => navigate("/dashboard")}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Dashboard" />
        </ListItemButton>

        {/* <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/buy-membership-plan"}
          onClick={() => navigate("/buy-membership-plan")}
        >
          <ListItemIcon>
            <SubscriptionsIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Buy Membership Plans" />
        </ListItemButton> */}

        {/* <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-ad-banner"}
          onClick={() => navigate("/manage-ad-banner")}
        >
          <ListItemIcon>
            <SmartDisplayIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="AD Banner Management" />
        </ListItemButton> */}
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-advertisements"}
          onClick={() => navigate("/manage-advertisements")}
        >
          <ListItemIcon>
            <SmartDisplayIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary="Manage Advertisements"
          />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/help-and-support"}
          onClick={() => navigate("/help-and-support")}
        >
          <ListItemIcon>
            <HelpCenterIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Help and Support" />
        </ListItemButton>

        {/* <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-orders"}
          onClick={() => navigate("/manage-orders")}
        >
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Orders" />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-promotion"}
          onClick={() => navigate("/manage-promotion")}
        >
          <ListItemIcon>
            <SwitchVideoIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary="Manage Promotional Video "
          />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-video"}
          onClick={() => navigate("/manage-video")}
        >
          <ListItemIcon>
            <VideoCameraBackIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Video" />
        </ListItemButton> */}
        {/* 
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-review"}
          onClick={() => navigate("/manage-review")}
        >
          <ListItemIcon>
            <RateReviewIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Review & Ratings" />
        </ListItemButton> */}

        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/analytics"}
          onClick={() => navigate("/analytics")}
        >
          <ListItemIcon>
            <LeaderboardIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Reports & Analytics" />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/settings"}
          onClick={() => navigate("/settings")}
        >
          <ListItemIcon>
            <SettingsIcon />
            {/* <SummarizeTwoToneIcon />
             */}
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Settings" />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/faq"}
          onClick={() => navigate("/faq")}
        >
          <ListItemIcon>
            <QuizIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary=" FAQ's" />
        </ListItemButton>
      </List>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
