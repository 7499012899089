import React, { useEffect } from "react";
import "./App.scss";
import Routing from "./Routes";

function App() {
  // useEffect(() => {
  //   async function setToken() {
  //     try {
  //       const fcmToken = await firebaseCloudMessaging.init();
  //       if (fcmToken) {
  //         console.log("fcm_Token", fcmToken);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   setToken();
  //   // Event listener that listens for the push notification event in the background
  //   if ("serviceWorker" in navigator) {
  //     navigator.serviceWorker.addEventListener("message", (event) => {
  //       try {
  //         event?.source?.postMessage("Hi client");
  //         console.log("event for the service worker", event);
  //         return toast(
  //           <div style={{ height: "100%" }}>
  //             <div>{event?.data?.notification?.title}</div>
  //             <div>{event?.data?.notification?.body}</div>
  //           </div>
  //         );
  //         return event;
  //       } catch (e) {
  //         console.log(e, "error");
  //         return e;
  //       }
  //     });
  //   }
  //   return () => {
  //     if ("serviceWorker" in navigator) {
  //       navigator.serviceWorker.removeEventListener("message", (e) =>
  //         console.log(e)
  //       );
  //     }
  //   };
  // }, []);
  return <Routing />;
}

export default App;
