export const API_URL = ""; //development

export const END_POINTS = {
  signUp: "register",
  login: "login",
  forgotPassword: "forgotPassword",
  get_user: "profile",
  update_profile: "updateProfile",
  mediaUpload: "upload",
  verifyOtp: "verifyOtp",
  resetPassword: "resetPassword",
  changePassword: "changePassword",
  logout: "logout",

  //user
  get_all_user: "user",
  user_by_id: "userById",
  edit_user_by_id: "user/",
  delete_user_by_id: "user",
  user_status: "userStatus/",
  user_csv: "admin/exportUser",

  //post for sell
  get_all_sell: "sellPost",
  sell_By_id: "sellPostById",
  sell_status: "/admin/sellStatus/",
  carPlate_service: "/admin/carPlateService",

  //auction
  get_all_auction: "/admin/auctionPost",
  auction_status: "/admin/auctionStatus/",
  update_auction_status: "/admin/updateAuctionStatus",
  getBiderByAuctionId: "/admin/getBiderByAuctionId",

  //offer
  get_all_offer: "/admin/offerPost",
  get_offer_byId: "admin/getofferPostById/",
  //cms
  get_cms: "/admin/cms",
  get_faq: "/admin/faq",

  //subscribtion
  subscription: "/admin/subscription",
  sub_by_id: "/admin/getSubscriptionById",
  sub_status: "/admin/subscriptionStatus/",

  //subAdmin
  subAdmin: "/admin/subAdmin",
  subAdmin_status: "/admin/subAdminStatus",
  subadmin_by_id: "/admin/subAdminById",

  //category
  category: "/admin/category",
  cate_by_id: "/admin/CategoryById",
  cate_status: "/admin/categoryStatus/",

  //notification
  notification: "/admin/notification",
  dashboard: "/admin/dashboard",
  userNotification: "/admin/userNotification",

  //services
  add_service: "/admin/services",
  getServiceFee: "/admin/getService",
  service_fee_status: "/admin/serviceStatusChange/",
  delete_service: "/admin/deleteService/",
  edit_service: "/admin/updateService/",

  getServiceMgmt: "/admin/getServiceMgmt",
  service_csv: "/admin/exportCsvServiceMgmt",
  update_service: "/admin/updateServiceMgmt",

  //banner
  add_banner: "/admin/banner",
  get_banner_carPlate: "/admin/carPlatenumber",
  get_car_plate: "/admin/carPlatenumber",
};
