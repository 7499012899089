import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchBar from "../../components/SearchBar";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageAdvertisements = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState<number>(0);

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Advertisements</h1>
        </div>
        <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Approved" {...a11yProps(0)} />
              <Tab label="Pending" {...a11yProps(1)} />
              <Tab label="Rejected" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">
                <SearchBar />
                <Box className="cards_header_right">
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-advertisements/add")}
                  >
                    Add Advertisement
                  </Button>
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Banner Image</TableCell>
                      <TableCell>Advertisement Title</TableCell>
                      <TableCell>Web Link</TableCell>
                      <TableCell>Status </TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">1</TableCell>
                      <TableCell>
                        {" "}
                        <figure className="bnr_img">
                          <img
                            src="/static/images/profile_placeholder.jpg"
                            alt=""
                          />
                        </figure>
                      </TableCell>
                      <TableCell>See the Northern Lights</TableCell>

                      <TableCell>www.northernlights.com</TableCell>

                      <TableCell>
                        <Switch {...label} defaultChecked size="small" />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate("/manage-advertisements/details")
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton
                          // onClick={() => navigate("/manageusers/usersform")}
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">
                <SearchBar />
                <Box className="cards_header_right">
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-advertisements/add")}
                  >
                    Add Advertisement
                  </Button>
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Banner Image</TableCell>
                      <TableCell>Advertisement Title</TableCell>
                      <TableCell>Web Link</TableCell>
                      {/* <TableCell>Status </TableCell> */}
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">1</TableCell>
                      <TableCell>
                        {" "}
                        <figure className="bnr_img">
                          <img
                            src="/static/images/profile_placeholder.jpg"
                            alt=""
                          />
                        </figure>
                      </TableCell>
                      <TableCell>See the Northern Lights</TableCell>

                      <TableCell>www.northernlights.com</TableCell>

                      {/* <TableCell>
                        <Switch {...label} defaultChecked size="small" />
                      </TableCell> */}
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate("/manage-advertisements/details")
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => navigate("/manageusers/usersform")}
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Box className="cards_header">
                <SearchBar />
                <Box className="cards_header_right">
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-advertisements/add")}
                  >
                    Add Advertisement
                  </Button>
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Banner Image</TableCell>
                      <TableCell>Advertisement Title</TableCell>
                      <TableCell>Web Link</TableCell>
                      {/* <TableCell>Status </TableCell> */}
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">1</TableCell>
                      <TableCell>
                        {" "}
                        <figure className="bnr_img">
                          <img
                            src="/static/images/profile_placeholder.jpg"
                            alt=""
                          />
                        </figure>
                      </TableCell>
                      <TableCell>See the Northern Lights</TableCell>

                      <TableCell>www.northernlights.com</TableCell>

                      {/* <TableCell>
                        <Switch {...label} defaultChecked size="small" />
                      </TableCell> */}
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate("/manage-advertisements/details")
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton
                          // onClick={() => navigate("/manageusers/usersform")}
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageAdvertisements;
